const DocMetadata = ({doc}) => {
  return (
    <div className="doc-metadata">
      <div className="doc-metadata-content">
        Progress: <h4>{Number(doc?.stats?.agent?.total) - Number(doc?.stats?.agent?.remaining)}/{doc?.stats?.agent?.total}</h4>
      </div>        
      <div className="doc-metadata-content">
        Batch: <h4>{doc?.payload?.clientBatchId}</h4>
      </div>
      <div className="doc-metadata-content">
        File: <h4>({doc?.payload?.folder}) {doc?.clientItemId}</h4>
      </div>
    </div>
  );
};

export default DocMetadata;
