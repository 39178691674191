import { Link, useLocation } from "react-router-dom";
import "./Menu.scss";
import { UserAuth } from "../../context/AuthContext.js";

const menu = [
  {
    id: 1,
    title: "main",
    listItems: [
      {
        id: 1,
        title: "Homepage",
        url: "/",
        icon: "home.svg",
      },
    ],
  },
  {
    id: 2,
    title: "lists",
    listItems: [
      {
        id: 1,
        title: "Users",
        url: "/dashboard/users",
        icon: "user.svg",
      },
      {
        id: 2,
        title: "Roles",
        url: "/dashboard/roles",
        icon: "view_list.svg",
      },
    ],
  },
];

const Menu = () => {
  const location = useLocation();
  const { handlerLogout } = UserAuth();

  return (
    <div className="menu">
      {menu.map((item) => (
        <div className="item" key={item.id}>
          <span className="title">{item.title}</span>
          {item.listItems.map((listItem) => (
            <Link
              to={listItem.url}
              className={`listItem ${
                location.pathname === listItem.url && "active-list"
              }`}
              key={listItem.id}
            >
              <img src={listItem.icon} alt="" />
              <span className="listItemTitle">{listItem.title}</span>
            </Link>
          ))}
        </div>
      ))}

      <div className="logout-wrapper" onClick={handlerLogout}>
        <img src={"/logout.svg"} alt="" />
        <span>logout</span>
      </div>
    </div>
  );
};

export default Menu;
