import { Outlet } from "react-router-dom";

import Menu from "../menu/Menu.js";
import Navbar from "../navbar/Navbar.js";
import "./AdminLayout.scss";

export const AdminLayout = () => {
  return (
    <div className="main">
      <Navbar />
      <div className="admin-container">
        <div className="menuContainer">
          <Menu />
        </div>
        <div className="contentContainer">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
