import React from "react";
import { Link } from "react-router-dom";

import "./pageNotFound.scss";

function PageNotFound({ slug }) {
  console.log({ slug });
  return (
    <div className="container-page-not-found">
      <h3 className="text-white">Page Not Found</h3>

      <Link className="go-to-home-page" to={slug}>
        Go to the home page
      </Link>
    </div>
  );
}

export default PageNotFound;
