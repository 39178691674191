import React, { useState } from "react";
import { Link } from "react-router-dom";

import ApiService from "../../helpers/Services.js";
import CardSkeleton from "../../components/skeleton/CardSkeleton.js";
import "./role.scss";

function Role() {
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const data = await ApiService.post("user-management", {
        action: "listRoles",
        payload: { vendor: "MT" },
      });
      setRoles(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    fetchRoles();
  }, []);

  return (
    <section className="role">
      <h1>Roles</h1>
      <div className="role-container">
        {isLoading ? (
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        ) : (
          roles?.map(({ rolename }) => (
            <Link
              key={rolename}
              to={`/dashboard/roles/${rolename}`}
              className={`role-card ${rolename}`}
            >
              <p>{rolename}</p>
            </Link>
          ))
        )}
      </div>
    </section>
  );
}

export default Role;
