// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { BrowserRouter, Routes, Route } from "react-router-dom";

import React from "react";
import LoginCard from "./components/LoginCard.js";
import Config from "./Config.js";
import Layout from "./components/Layout.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { pdfjs } from "react-pdf";

import User from "./pages/user/User.js";
import { AdminLayout } from "./components/layout/AdminLayout.js";
import PageNotFound from "./components/pageNotFound/PageNotFound.js";
import RequireAuth from "./routers/RequireAuth.js";
import Role from "./pages/role/Role.js";
import RoleDetail from "./components/roleDetail/RoleDetail.js";
import AdminAuth from "./routers/AdminAuth.js";

//pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const App = ({ isSignedIn, token }) => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={"/login"} element={<LoginCard />} />

          <Route
            path={"/"}
            element={
              <RequireAuth isSignedIn={isSignedIn}>
                <Layout token={token} />
              </RequireAuth>
            }
          />

          <Route
            path={"/dashboard"}
            element={
              <AdminAuth isSignedIn={isSignedIn}>
                <AdminLayout />
              </AdminAuth>
            }
          >
            <Route path={"/dashboard/users"} element={<User token={token} />} />
            <Route path={"/dashboard/roles"} element={<Role />} />
            <Route path={"/dashboard/roles/:role"} element={<RoleDetail />} />
            <Route
              path={"*"}
              element={<PageNotFound slug="/dashboard/users" />}
            />
          </Route>
          <Route path={"*"} element={<PageNotFound slug="/" />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
