import { useState } from "react";
import { toast } from "react-toastify";

import ApiService from "../../helpers/Services.js";
import "./Add.scss";

const Add = (props) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      await ApiService.post("user-management", {
        action: "createUser",
        payload: {
          vendor: "MT",
          email: email,
        },
      });

      toast.success("User Created Successfully!", {
        position: "top-right",
      });
      props?.fetchUsers();
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }

    props.setOpen(false);
  };

  return (
    <div className="add">
      <div className="modal-user">
        <span className="close-icon" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>Add new {props.slug}</h1>
        <form onSubmit={handleSubmit}>
          <div className="item">
            <label>Email</label>
            <input
              type="email"
              placeholder={"Enter email"}
              value={email}
              onChange={handleInputChange}
            />
          </div>

          <button className="add-btn">Add</button>
        </form>
      </div>
    </div>
  );
};

export default Add;
