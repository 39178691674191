import React from "react";
import { ClockLoader } from "react-spinners";

const Loader = () => {
  const styles = {
    loaderBlock: {
      minHeight: "60vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backdropFilter: "blur(10px)",
    },
  };
  return (
    <div style={styles.loaderBlock}>
      <ClockLoader />
    </div>
  );
};

export default Loader;
