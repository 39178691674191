import { useState } from "react";
import { toast } from "react-toastify";

import ApiService from "../../helpers/Services.js";
import ModalFormSkeleton from "../skeleton/ModalFormSkeleton.js";
import "./assignRole.scss";

const AssignRole = (props) => {
  const { email, title, isAssignRole, roleName } = props.userRef;
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [checkedRoles, setCheckedRoles] = useState({
    Admin: false,
    Frontline: false,
    Supervisor: false,
  });

  const handleCheckboxChange = (id, isChecked) => {
    setCheckedRoles((prevRoles) => ({
      ...prevRoles,
      [id]: isChecked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rolesToAssign = Object.entries(checkedRoles)
      .filter(([_, isChecked]) => isChecked)
      .map(([id]) => id);

    if (!rolesToAssign.length) {
      alert("Please select a role before submitting.");
      return;
    }

    try {
      await ApiService.post("user-management", {
        action: "updateUserRoles",
        payload: {
          vendor: "MT",
          email: email,
          roles: rolesToAssign,
        },
      });

      toast.success(`${title} Successfully!`, {
        position: "top-right",
      });
      props?.fetchUsers();
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }

    props.setOpen(false);
  };

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const data = await ApiService.post("user-management", {
        action: "listRoles",
        payload: { vendor: "MT" },
      });

      setRoles(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    fetchRoles();
  }, []);

  return (
    <div className="assign-role-container">
      <div className="modal-user">
        <span className="close-icon" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>{title ?? "Role"}</h1>
        {isLoading ? (
          <ModalFormSkeleton />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="item">
              <label>Email</label>
              <input type="email" disabled value={email} />
            </div>

            {!isAssignRole && (
              <div className="role-assign-txt">
                Please check the roles you want to keep.
              </div>
            )}
            <div className="role-checkbox-container">
              {roles?.map(({ rolename }) => (
                <div key={rolename}>
                  <input
                    type="checkbox"
                    id={rolename}
                    checked={checkedRoles[rolename]}
                    onChange={(e) =>
                      handleCheckboxChange(rolename, e.target.checked)
                    }
                  />
                  <label htmlFor={rolename}>{rolename}</label>
                </div>
              ))}
            </div>

            <button
              className={isAssignRole ? "assign-role-btn" : "remove-role-btn"}
            >
              {isAssignRole ? "Assign Role" : "Remove Role"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AssignRole;
