import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../context/AuthContext.js";

function AdminAuth({ children }) {
  let location = useLocation();
  const { user } = UserAuth();

  if (!user?.isAdmin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default AdminAuth;
