import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "./DocumentViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const AnnotatedDocumentViewer = ({ doc, highlight, highlightImpLabels }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [initialScale, setInitialScale] = useState(1.5);
  const [notes, setNotes] = useState([]);
  const [highlightCheckbox, setHighlightCheckbox] = useState(false);
  const viewerRef = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    // Store the initial scale
    setInitialScale(scale);
  };

  useEffect(() => {
    if (highlightCheckbox) {
      let labels = [];
      let notes = [];

      for (var label in highlightImpLabels) {
        const geoLocation = highlightImpLabels[label]['g'];
        const value = highlightImpLabels[label]['t'];
        const pageIndex = highlightImpLabels[label]['p'];
        if (geoLocation && value) {
          const { Height, Width, Left, Top } = geoLocation?.BoundingBox;
          let area = [
            {
              height: Height * 100,
              left: Left * 100,
              top: Top * 100,
              pageIndex: pageIndex,
              width: Width * 100,
            },
          ];
          labels.push({ area, value });
        }
      }
      let id = 0;

      for (var label in labels) {
        const { area, value } = labels[label];
        if (area?.length && value) {
          const note = {
            id: id,
            content: value,
            highlightAreas: area,
            pageIndex: area[0].pageIndex,
            highlightAll: true,
            quote: value,
          };
          notes.push(note);
          id++;
        }
      }
      if (notes.length > 0) {
        setNotes(notes);
      } else {
        setNotes([]);
      }
    } else {
      setNotes([]);
    }
  }, [highlightImpLabels, highlightCheckbox, scale]);

  useEffect(() => {
    if (highlight?.area?.length && highlight?.value) {
      let id = notes.length + 1;
      const note = {
        id: id,
        content: highlight.value,
        highlightAreas: highlight.area.map(area => ({
          ...area,
          height: area.height * initialScale,
          left: area.left * initialScale,
          top: area.top * initialScale,
          width: area.width * initialScale,
        })),
        quote: highlight.value,
        pageIndex: highlight.area[0].pageIndex,
      };
      setNotes([note]);
      let pageNum = highlight.area[0].pageIndex + 1; // Pages are 1-based in react-pdf
      setPageNumber(pageNum);
    } else {
      setNotes([]);
    }
  }, [highlight, initialScale]);

  const handleCheckboxChange = () => {
    setHighlightCheckbox((prevState) => !prevState);
  };

  const checkOnload = () => {
    setHighlightCheckbox(true);
  };

  useEffect(() => {
    checkOnload();
  }, []);

  const handleZoomIn = () => {
    setScale(scale + 0.5);
  };

  const handleZoomOut = () => {
    setScale(scale > 1 ? scale - 0.5 : 1);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = doc.signedURL;
    link.download = 'document.pdf';
    link.click();
  };

  const handleFullScreen = () => {
    if (viewerRef.current.requestFullscreen) {
      viewerRef.current.requestFullscreen();
    } else if (viewerRef.current.mozRequestFullScreen) { /* Firefox */
      viewerRef.current.mozRequestFullScreen();
    } else if (viewerRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      viewerRef.current.webkitRequestFullscreen();
    } else if (viewerRef.current.msRequestFullscreen) { /* IE/Edge */
      viewerRef.current.msRequestFullscreen();
    }
  };

  const renderHighlights = (pageIndex) => {
    return notes
      .filter(note => note.pageIndex === pageIndex)
      .map((note, index) => (
        note.highlightAreas.map((area, i) => (
          <div
            key={`${note.id}-${index}-${i}`}
            className="highlight"
            style={{
              top: `${area.top * scale / initialScale}%`,
              left: `${area.left * scale / initialScale}%`,
              height: `${area.height * scale / initialScale}%`,
              width: `${area.width * scale / initialScale}%`,
            }}
            title={note.content}
          />
        ))
      ));
  };

  const handlePageChange = (newPageNumber) => {
    if (newPageNumber >= 1 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
    }
  };

  return (
    <div className="pdf-viewer" ref={viewerRef}>
      <div className="toolbar">
        <button className="toolbar-button" onClick={handleZoomOut}>
          <span>-</span>
        </button>
        <span className="toolbar-scale">{Math.round(scale * 100)}%</span>
        <button className="toolbar-button" onClick={handleZoomIn}>
          <span>+</span>
        </button>
        <div className="toolbar-page-nav">
          <button
            className="toolbar-button"
            onClick={() => handlePageChange(pageNumber - 1)}
          >
            <span>&uarr;</span>
          </button>
          <input
            className="toolbar-page-input"
            type="number"
            value={pageNumber}
            onChange={(e) => handlePageChange(Number(e.target.value))}
            min={1}
            max={numPages}
          />
          <span> / {numPages}</span>
          <button
            className="toolbar-button"
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            <span>&darr;</span>
          </button>
        </div>
        <button className="toolbar-button" onClick={handleFullScreen}>
          <span>&#x26F6;</span>
        </button>
        <button className="toolbar-button" onClick={handleDownload}>
          <span>&#x1F4BE;</span>
        </button>
        <div className="toolbar-highlight">
          <input
            type="checkbox"
            checked={highlightCheckbox}
            onChange={handleCheckboxChange}
          />
          <label>Highlight key labels</label>
        </div>
      </div>
      <Document
        file={doc?.signedURL}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <div className="pdf-page-container">
          <Page
            pageNumber={pageNumber}
            scale={scale}
            renderTextLayer={true}
          />
          {renderHighlights(pageNumber - 1)}
        </div>
      </Document>
    </div>
  );
};

export default AnnotatedDocumentViewer;
