import { useEffect, useRef, useState } from "react";

import DataTable from "../../components/dataTable/DataTable.js";
import Add from "../../components/add/Add.js";
import storage from "../../helpers/storage.js";
import ApiService from "../../helpers/Services.js";
import AssignRole from "../../components/assignRole/AssignRole.js";
import TableSkeleton from "../../components/skeleton/TableSkeleton.js";
import "./User.scss";

const columns = [
  {
    field: "userid",
    type: "string",
    headerName: "Email",
    renderHeader: (params) => (
      <p className="email-container">
        <strong>{"Email "}</strong>{" "}
      </p>
    ),
    width: 200,
  },
  {
    field: "rolename",
    type: "string",
    headerName: "Current Role",
    headerAlign: "center",
    width: 200,
    // rendercell if included then serach filter is not working on this field
    renderCell: (params) => {
      const role = params.row.rolename ?? "NA";
      return <p style={{ margin: "auto" }}>{role}</p>;
    },
  },

  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    width: 150,
    renderCell: (params) => {
      const isActive = params.row.status === "Active";
      return (
        <div className={isActive ? "status-active" : "status-disabled"}>
          <p>{isActive ? "Active" : "Disabled"}</p>
        </div>
      );
    },
  },
];

const Users = ({ token }) => {
  const [open, setOpen] = useState(false);
  const [isAssignRole, setIsAssignRole] = useState(false);
  const [userList, setUserList] = useState([]);
  const selectedUserRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const assignColumn = {
    field: "assign",
    headerName: "Role Action",
    width: 180,
    headerAlign: "center",

    renderCell: (params) => {
      const hasRole = params.row.rolename !== null;
      const isDisabled = params.row.status === "Disabled";

      if (isDisabled) {
        return <div className="user-not-active">--</div>;
      }
      return (
        <div className="role-action">
          {/* <Link to={`/${props.slug}/${params.row.id}`}> */}
          {hasRole ? (
            <button
              className="change-role"
              onClick={() => {
                setIsAssignRole(true);
                selectedUserRef.current = {
                  email: params.row.userid,
                  title: "Change Role",
                  isAssignRole: false,
                  roleName: params.row.rolename,
                };
              }}
            >
              Change Role
            </button>
          ) : (
            <button
              className="assign-role"
              onClick={() => {
                setIsAssignRole(true);
                selectedUserRef.current = {
                  email: params.row.userid,
                  title: "Assign Role",
                  roleName: params.row.rolename,
                  isAssignRole: true,
                };
              }}
            >
              Assign Role
            </button>
          )}
        </div>
      );
    },
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      let token = storage.getToken();
      console.log({ token });
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = await ApiService.post(
        "user-management",
        {
          action: "listUsers",
          payload: {
            vendor: "MT",
          },
        },
        headers
      );

      const refactorId = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setUserList(refactorId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open || isAssignRole) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open, isAssignRole]);

  useEffect(() => {
    fetchUsers();
    storage.clearFileName();
  }, []);

  return (
    <div className="users">
      <div className="info">
        <h1>Users</h1>
        <button onClick={() => setOpen(true)}>Add New User</button>
      </div>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <DataTable
          slug="users"
          columns={[...columns, assignColumn]}
          rows={userList}
          fetchUsers={fetchUsers}
        />
      )}
      {open && (
        <Add
          token={token}
          slug="user"
          setOpen={setOpen}
          fetchUsers={fetchUsers}
        />
      )}
      {isAssignRole && (
        <AssignRole
          userRef={selectedUserRef?.current}
          setOpen={setIsAssignRole}
          fetchUsers={fetchUsers}
        />
      )}
    </div>
  );
};

export default Users;
