import Config from "../../Config.js";
import "./navbar.scss";

const logoName = Config.LogoName;
const Navbar = () => {
  return (
    <div className="navbar2">
      <div className="navbar2-logo">
        <img src="logo.svg" alt="" />
        <span>{logoName}</span>
      </div>
    </div>
  );
};

export default Navbar;
