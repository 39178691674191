import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import ApiService from "../../helpers/Services.js";
import "./dataTable.scss";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

const DataTable = (props) => {
  const deactivateHandler = async (email) => {
    try {
      await ApiService.post("user-management", {
        action: "deactivateUser",
        payload: {
          vendor: "MT",
          email: email,
        },
      });

      props?.fetchUsers();

      toast.success("User deactivated", {
        position: "top-right",
      });
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const activateHandler = async (email) => {
    try {
      await ApiService.post("user-management", {
        action: "activateUser",
        payload: { vendor: "MT", email: email },
      });
      props?.fetchUsers();

      toast.success("User Activated", {
        position: "top-right",
      });
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 200,
    headerAlign: "center",

    renderCell: (params) => {
      const isActive = params.row.status === "Active";

      return (
        <div className="action">
          {isActive ? (
            <button
              className="deactivate button"
              onClick={() => deactivateHandler(params.row.userid)}
            >
              De-Activate
            </button>
          ) : (
            <button
              className=" status-active activate"
              onClick={() => activateHandler(params.row.userid)}
            >
              Activate
            </button>
          )}
        </div>
      );
    },
  };

  const handleSelectionChange = (newSelectionModel) => {
    // Get the full details of the selected rows
    const selectedRows = props.rows.filter((row) =>
      newSelectionModel.includes(row.id)
    );
    console.log("Selected user details:", selectedRows);
  };

  const shouldShowActionColumn = props?.hideActionColumn;

  const columns = shouldShowActionColumn
    ? [...props.columns]
    : [...props.columns, actionColumn];

  return (
    <div className="dataTable">
      <DataGrid
        className="dataGrid"
        rows={props.rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        density="comfortable"
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
        // disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        onRowSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
};

export default DataTable;
