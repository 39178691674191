import React from "react";

function TableSkeleton() {
  return (
    <div>
      <h4 className="custom-title loading-custom "></h4>
      <div className="loading-custom custom-description"></div>
    </div>
  );
}

export default TableSkeleton;
