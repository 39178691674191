import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";

import DataTable from "../dataTable/DataTable.js";
import ApiService from "../../helpers/Services.js";
import TableSkeleton from "../skeleton/TableSkeleton.js";
import "./roleDetail.scss";

const columns = [
  {
    field: "userid",
    type: "string",
    headerName: "Email",
    width: 200,
  },
  {
    field: "rolename",
    type: "string",
    headerName: "Role",
    width: 100,
  },
];

function RoleDetail() {
  let { role } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const fetchUserInRole = async () => {
    try {
      setIsLoading(true);
      const data = await ApiService.post("user-management", {
        action: "listUsersInRoles",
        payload: { vendor: "MT", roleName: role },
      });

      const refactorId = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setRoles(refactorId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    fetchUserInRole();
  }, []);

  return (
    <section className="role-detail">
      <div className="role-container">
        <Link to={"/dashboard/roles/admin"} className={`role-card ${role}`}>
          <p>{role}</p>
        </Link>
      </div>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className="role-table">
          <DataTable
            slug="users"
            columns={columns}
            rows={roles}
            hideActionColumn
          />
        </div>
      )}
    </section>
  );
}

export default RoleDetail;
