import { PDFDocument } from 'pdf-lib';

const hasAnnotations = async (pdfUrl) => {
  try {
    const arrayBuffer = await fetch(pdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    for (const page of pdfDoc.getPages()) {
      const annotations = page.node.Annots();
      if (annotations) {
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error("Error checking annotations:", error);
    return false;
  }
};

export default hasAnnotations;
