export const dummySchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    amount: {
      type: ["string", "null"],
      pattern: "^(\\d*(\\.\\d{1,2})?)?$",
      uiLabel: "Invoice Amount",
      uiOrder: 1,
    },
    customerAccount: {
      type: "string",
      pattern: "^[A-Za-z0-9]+$",
      uiLabel: "Customer Account",
      uiOrder: 2,
    },
    dueDate: {
      type: "string",
      format: "date",
      uiLabel: "Due Date",
      uiOrder: 3,
    },
    freightAmount: {
      type: ["string", "null"],
      pattern: "^(\\d*(\\.\\d{1,2})?)?$",
      uiLabel: "Freight Amount",
      uiOrder: 4,
    },
    invoiceNumber: {
      type: "string",
      uiLabel: "Invoice Number",
      uiOrder: 5,
    },
    issuedDate: {
      type: "string",
      format: "date",
      uiLabel: "Issued Date",
      uiOrder: 6,
    },
    paymentTerms: {
      type: "string",
      uiLabel: "Payment Terms",
      uiOrder: 8,
    },
    ponumber: {
      type: "string",
      pattern: "^[A-Za-z0-9-]*$",
      uiLabel: "PO Number",
      uiOrder: 9,
    },
    tax: {
      type: "string",
      pattern: "^(\\d*(\\.\\d{1,2})?)?$",
      uiLabel: "Tax",
      uiOrder: 10,
    },
    vendorEmail: {
      type: "string",
      pattern: "^([^\\s@]+@[^\\s@]+\\.[^\\s@]+)?$",
      uiLabel: "Vendor Email",
      uiOrder: 11,
    },
    vendorName: {
      type: "string",
      uiLabel: "Vendor Name",
      uiOrder: 12,
    },
    vendorPhone: {
      type: "string",
      uiLabel: "Vendor Phone",
      uiOrder: 13,
    },
    taxId: {
      type: "string",
      pattern: "^[A-Za-z0-9-]*$",
      uiLabel: "Tax ID",
      uiOrder: 14,
    },
    lineItems: {
      type: "array",
      uiLabel: "Line Items",
      uiOrder: 100,
      items: {
        type: "object",
        properties: {
          desc: {
            type: "string",
            uiLabel: "Description",
            uiOrder: 1,
          },
          qty: {
            type: ["string", "null"],
            pattern: "^(\\d*(\\.\\d{1,2})?)?$",
            uiLabel: "Quantity",
            uiOrder: 2,
          },
          totalAmount: {
            type: ["string", "null"],
            pattern: "^(\\d*(\\.\\d{1,2})?)?$",
            uiLabel: "Total Amount",
            uiOrder: 3,
          },
          unitPrice: {
            type: ["string", "null"],
            pattern: "^(\\d*(\\.\\d{1,2})?)?$",
            uiLabel: "Unit Price",
            uiOrder: 4,
          },
        },
        required: ["desc", "qty", "totalAmount", "unitPrice"],
      },
    },
  },
  required: ["amount", "invoiceNumber", "issuedDate", "vendorName"],
};

export const dummyFormData = {
  issuedDate: "2023-11-09",
  dueDate: "2023-12-10",
  amount: "414.5",
  invoiceNumber: "BE00253",
  ponumber: "VC0DDF8V",
  paymentTerms: "Net 25th",
  tax: "3.11",
  taxLines: [],
  taxId: "",
  freightAmount: "0.0",
  vendorName: "Covetrus North America",
  vendorEmail: "",
  vendorPhone: "1-800-258-2148",
  customerAccount: "",
  lineItems: [
    {
      desc: "sad",
      qty: "sd",
      totalAmount: "asd",
      unitPrice: "sd",
    },
  ],
};

export const detectAndFormatDate = (str) => {
  const formats = [
    { regex: /^\d{4}-\d{2}-\d{2}$/, format: "YYYY-MM-DD" },
    { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: "MM/DD/YYYY" },
    { regex: /^\d{2}-\d{2}-\d{4}$/, format: "MM-DD-YYYY" },
    { regex: /^\d{2}\.\d{2}\.\d{4}$/, format: "MM.DD.YYYY" },
    { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: "DD/MM/YYYY" },
    { regex: /^\d{2}-\d{2}-\d{4}$/, format: "DD-MM-YYYY" },
    { regex: /^\d{2}\.\d{2}\.\d{4}$/, format: "DD.MM.YYYY" },
  ];

  for (const { regex, format } of formats) {
    if (regex.test(str)) {
      // Parse the date and reformat it to MM/DD/YYYY
      const date = new Date(str.replace(/[-\/.]/g, "/"));
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
  }

  return null;
};
