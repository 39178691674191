import React, { useEffect, useState } from "react";
import AnnotatedDocumentViewer from "./AnnotatedDocumentViewer.js"; // Import the first DocumentViewer component
import NonAnnotatedDocumentViewer from "./NonAnnotatedDocumentViewer.js"; // Import the second DocumentViewer component
import hasAnnotations from "./hasAnnotations.js"; // Import the utility function

const DocumentRouter = ({ doc, highlight, highlightImpLabels }) => {
  const [isAnnotated, setIsAnnotated] = useState(false);

  useEffect(() => {
    const checkIfAnnotated = async () => {
      const annotated = await hasAnnotations(doc.signedURL);
      setIsAnnotated(annotated);
    };

    checkIfAnnotated();
  }, [doc.signedURL]);

  return (
    <>
      {isAnnotated ? (
        <AnnotatedDocumentViewer
          doc={doc}
          highlight={highlight}
          highlightImpLabels={highlightImpLabels}
        />
      ) : (
        <NonAnnotatedDocumentViewer
          doc={doc}
          highlight={highlight}
          highlightImpLabels={highlightImpLabels}
        />
      )}
    </>
  );
};

export default DocumentRouter;
