import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/global.css";
import React from "react";
import ReactDOM from "react-dom";
import lambdaClientFactory from "./helpers/LambdaClientFactory.js";
import s3ClientFactory from "./helpers/S3ClientFactory.js";
import App from "./App.js";
import { PasteDataProvider } from "./context/PasteDataContext.js";
import storage from "./helpers/storage.js";
import { AuthContextProvider } from "./context/AuthContext.js";

const params = new URLSearchParams(window.location.hash.slice(1));
const idTokenFromURL = params.get("id_token");

const idTokenFromLocalStorage = storage.getToken();
const idToken = idTokenFromURL || idTokenFromLocalStorage;

if (idTokenFromURL) {
  storage.setToken(idToken);
  // Remove the id_token from the URL and set the URL to the actual origin
  window.history.replaceState({}, "", window.location.origin);
}

let lambdaClients = lambdaClientFactory(idToken);
let s3Clients = s3ClientFactory(idToken);

const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <AuthContextProvider>
        <PasteDataProvider>
          <App
            isSignedIn={!!idToken}
            token={idToken}
            {...lambdaClients}
            {...s3Clients}
          />
        </PasteDataProvider>
      </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

render();
