import axios from "axios";
import Config from "../Config.js";
import storage from "./storage.js";

const API_BASE_URL = Config.APIUrl;

const setBusyCursor = () => {
  document.body.style.cursor = "wait";
};

const setDefaultCursor = () => {
  document.body.style.cursor = "default";
};

const redirectToLogin = () => {
  // Replace '/login' with your login route
  window.location.href = Config.LoginUrl;
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ApiService = {
  // GET requests
  get: async (endpoint, headers = {}) => {
    setBusyCursor();
    try {
      let token = storage.getToken();
      const response = await axios.get(`${API_BASE_URL}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      });
      setDefaultCursor();
      return response.data;
    } catch (error) {
      setDefaultCursor();
      if (error.response) {
        if (error.response.status === 401) {
          redirectToLogin();
        } else if (error.response.status >= 500) {
          await delay(2000); // Delay for 2 seconds
          return ApiService.get(endpoint, headers); // Retry the request
        }
      }
      console.error("Error making GET request:", error);
      throw error.response ? error.response.data : error;
    }
  },

  // POST requests
  post: async (endpoint, data, headers = {}) => {
    setBusyCursor();
    let token = storage.getToken();
    try {
      const response = await axios.post(`${API_BASE_URL}/${endpoint}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      });
      setDefaultCursor();
      return response.data;
    } catch (error) {
      setDefaultCursor();
      if (error.response) {
        if (error.response.status === 401) {
          redirectToLogin();
        } else if (error.response.status >= 500) {
          await delay(2000); // Delay for 2 seconds
          return ApiService.post(endpoint, data, headers); // Retry the request
        }
      }
      console.error("Error making POST request:", error);
      throw error.response ? error.response.data : error;
    }
  },
};

export default ApiService;
