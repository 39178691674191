import React, { useState } from "react";
import {
  Button,
  Position,
  PrimaryButton,
  Tooltip,
} from "@react-pdf-viewer/core";
import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";


const useHighlightNotes = () => {
  const [notes, setNotes] = useState([]);
  const [message, setMessage] = useState("");
  let noteId = notes.length;

  const addNote = (props) => {
    if (message !== "") {
      const note = {
        id: ++noteId,
        content: message,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText,
      };
      setNotes(notes.concat([note]));
      props.cancel();
    }
  };

  const renderHighlightTarget = (props) => (
    <div
      style={{
        background: "#eee",
        display: "flex",
        position: "absolute",
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: "translate(0, 8px)",
        zIndex: 1,
      }}
    >
      <Tooltip
        position={Position.TopCenter}
        target={
          <Button onClick={props.toggle}>
            <MessageIcon />
          </Button>
        }
        content={() => <div style={{ width: "100px" }}>Add a note</div>}
        offset={{ left: 0, top: -8 }}
      />
    </div>
  );

  const renderHighlightContent = (props) => (
    <div
      style={{
        background: "#fff",
        border: "1px solid rgba(0, 0, 0, .3)",
        borderRadius: "2px",
        padding: "8px",
        position: "absolute",
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        zIndex: 1,
      }}
    >
      <div>
        <textarea
          rows={3}
          style={{
            border: "1px solid rgba(0, 0, 0, .3)",
          }}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "8px",
        }}
      >
        <div style={{ marginRight: "8px" }}>
          <PrimaryButton onClick={() => addNote(props)}>Add</PrimaryButton>
        </div>
        <Button onClick={props.cancel}>Cancel</Button>
      </div>
    </div>
  );


  const renderHighlights = (props) => (
    
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                className="highlight-area"
                style={props.getCssProperties(area, props.rotation)}	           
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const handleMouseEnter = (area) => {
    console.log('Mouse entered');
};


const handleMouseLeave = () => {
    // Logic to remove zoom effect
    console.log('Mouse handleMouseLeave');

};
  
  

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  return { highlightPluginInstance, setNotes , noteId};
};

export default useHighlightNotes;
