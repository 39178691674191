import React, { useState, useRef, useEffect } from "react";
import UTIF from "utif2";
import { detectAndFormatDate } from "../helpers/utils.js";

const updateCanvasPositionFromImg = (canvas, img) => {
  canvas.style.position = "absolute";
  canvas.style.left = `${img.offsetLeft}px`;
  canvas.style.top = `${img.offsetTop}px`;
  canvas.width = img.offsetWidth;
  canvas.height = img.offsetHeight;
};

const renderPolygon = (context, polygons, canvas) => {
  context.strokeStyle = "Tomato";
  context.lineWidth = 2;
  context.beginPath();
  const points = Object.keys(polygons).map((k) => polygons[k]);
  context.moveTo(canvas.width * points[0].X, canvas.height * points[0].Y);
  points
    .slice(1)
    .forEach((point) =>
      context.lineTo(canvas.width * point.X, canvas.height * point.Y)
    );
  context.closePath();
  context.stroke();
};

const DocumentDisplay = ({
  src,
  highlight,
  setHighlight,
  highlightImpLabels,
}) => {
  const [imgDataUrl, setImgDataUrl] = useState();
  const [highlightCheckbox, setHighlightCheckbox] = useState(false);

  const imgRef = useRef();
  const canvasRef = useRef();

  const img = imgRef.current;
  const canvas = canvasRef.current;
  const context = canvas?.getContext("2d");

  const handleCheckboxChange = () => {
    setHighlight({});
    setHighlightCheckbox((prevState) => !prevState);
  };

  useEffect(() => {
    if (!img || !canvas || !imgDataUrl) return;

    if (highlightCheckbox) {
      updateCanvasPositionFromImg(canvas, img);
      for (var label in highlightImpLabels) {
        const geoLocation = highlightImpLabels[label]["g"];
        const value = highlightImpLabels[label]["t"];
        const pageIndex = highlightImpLabels[label]["p"];
        if (geoLocation && value) {
          renderPolygon(context, geoLocation.Polygon, canvas);
        }
      }
    } else {
      context?.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [highlightImpLabels, highlightCheckbox, imgRef, canvas, imgDataUrl]);

  useEffect(() => {
    if (!highlightCheckbox && !highlight) {
      context?.clearRect(0, 0, canvas.width, canvas.height);
      return;
    }
    if (!highlight || Object.keys(highlight).length === 0) return;

    const { area, value } = highlight;
    if (!area?.length || !value || !img || !canvas) return;

    updateCanvasPositionFromImg(canvas, img);
    const formattedDate = detectAndFormatDate(value);
    for (var label in highlightImpLabels) {
      const geoLocation = highlightImpLabels[label]["g"];
      const currentValue = highlightImpLabels[label]["t"];

      if (currentValue === value || currentValue === formattedDate) {
        // If they match, draw the highlight
        renderPolygon(context, geoLocation.Polygon, canvas);
      }
    }
  }, [highlight]);

  useEffect(() => {
    if (highlightCheckbox && Object.keys(highlight).length) {
      setHighlightCheckbox(false);
    }
  }, [highlightCheckbox, highlight]);

  useEffect(() => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    xhr.open("GET", src);
    xhr.onload = () => {
      setImgDataUrl(UTIF.bufferToURI(xhr.response));
    };
    xhr.send();
  }, [src]);

  return (
    <div className="tiff-viewer pdf-testing">
      {imgDataUrl ? (
        <>
          <div className="toolbar">
            <input
              type="checkbox"
              checked={highlightCheckbox}
              onChange={handleCheckboxChange}
            />
            <label>Highlight key labels</label>
          </div>
          <img
            ref={imgRef}
            src={imgDataUrl}
            className="img-fluid"
            alt="Extraction source"
          />
          <canvas ref={canvasRef} />
        </>
      ) : (
        <div className="my-2">Loading Document...</div>
      )}
    </div>
  );
};

export default DocumentDisplay;
