import React, { useState, useEffect, useContext } from "react";
import PasteDataContext from "../context/PasteDataContext.js";
import IsValidPanel from "./IsValidPanel.js";

const JsonSchemaForm = ({
  highlightAlerts = false,
  schema,
  formData: initialFormData,
  postData,
  geos = {},
  getSelectedInput,
  disableForm = false,
  showGeos = true,
  useForTrain = () => {
    return;
  },
  getTask = () => {
    return;
  },
  isValidPayload,
  token,
}) => {
  const { pasteData, setPasteData } = useContext(PasteDataContext);
  const [formData, setFormData] = useState(initialFormData || {});
  const [formErrors, setFormErrors] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  

  useEffect(() => {
    setFormData(initialFormData || {});
  }, [initialFormData]);

  useEffect(() => {
    if (pasteData && !disableForm) {
      // Create a copy of the current line items
      const updatedLineItems = [...(formData.lineItems || [])];
  
      // Insert the new item from pasteData at the specified index
      // The first parameter of splice is the index, the second parameter (0) means no items are removed
      updatedLineItems.splice(pasteData?.index, 0, pasteData?.item); 
  
      // Update the formData state with the new line items array
      setFormData((prevData) => ({ ...prevData, lineItems: updatedLineItems }));
  
      // Reset pasteData to null
      setPasteData(null);
    }
  }, [pasteData, disableForm, formData.lineItems]);


  const styles = {
    formContainer: {
      // padding: "20px",
      // margin: "20px",
      backgroundColor: "whiteSmoke",
      borderRadius: "10px",
    },
    formLabel: {
      display: "block",
      marginBottom: "5px",
    },
    formRequired: {
      color: "red",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
    formGroup: {
      marginBottom: "15px",
    },
    tableSection: {
      minWidth: "300px",
    },
    input: {
      padding: "8px",
      borderRadius: "5px",
      width: "90%",
      border: "1px solid #d7d7d7",
      marginBottom: "5px",
      outline: "none",
    },
    addButton: {
      backgroundColor: "#1c9d46",
      color: "white",
      padding: "8px 15px",
      borderRadius: "5px",
      border: "none",
    },
    removeButton: {
      backgroundColor: "red",
      color: "white",
      padding: "8px 15px",
      borderRadius: "5px",
      border: "none",
    },
    pasteButton: {
      backgroundColor: "#fff",
      color: "#8500ff",
      padding: "0 5px",
      borderRadius: "5px",
      border: "none",
      fontSize: "2.5rem",
      cursor: "pointer",
    },
    submitButton: {
      backgroundColor: "#2a9de9",
      color: "white",
      padding: "10px 20px",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
    },
    errorAlert: {
      marginBottom: "20px",
      padding: "1rem",
      border: "1px solid red",
      backgroundColor: "#ff000026",
      color: "red",
      borderRadius: "5px",
      maxHeight: "30rem",
      overflow: "auto",
    },
    disabledButton: {
      backgroundColor: "#ccc",
      color: "#666",
      padding: "8px 15px",
      borderRadius: "5px",
      border: "none",
      cursor: "not-allowed",
    },
    disabledInput: {
      backgroundColor: "#f4f4f4",
      outline: "none",
    },
    lineItemBlock: {
      // width: "50rem",
    },
  };

  const handleFocus = (geoLocation, value) => {
    getSelectedInput({ geoLocation, value });
  };

  const renderInput = (fieldName, fieldSchema) => {
    if (fieldSchema.type === "array") {
      return <hr style={{ margin: "10px 0", border: "1px solid #ccc" }} />;
    }


    const commonInputProps = {
      type: "text",
      name: fieldName,
      value: formData[fieldName] || "",
      onClick: () => {
        showGeos && handleFocus(geos[fieldName], formData[fieldName]);
      },
      onChange: handleChange,
      placeholder: fieldSchema.uiLabel || "",
      style: { ...styles.input, ...(disableForm && styles.disabledInput) },
    };
    
    if (fieldSchema.format === "date") {
      return <input {...commonInputProps} type="date" readOnly={disableForm}/>;
    }    

    if (disableForm) {
      return <input {...commonInputProps} readOnly />;
    }

    const pattern = fieldSchema.pattern;
    const inputValue = formData[fieldName] || "";

    const isValid = !pattern || new RegExp(pattern).test(inputValue);

    const inError = Object.keys(formErrors).filter((err) => err == fieldName);

    return (
      <>
        <input {...commonInputProps} />
        {!isValid && formData[fieldName] != "" && !inError.length && (
          <div style={{ color: "red" }}>
            {`Invalid ${fieldSchema.uiLabel || fieldName} pattern`}
          </div>
        )}
      </>
    );
  };    

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // setFormErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleRemoveArrayItem = (index) => {
    const updatedLineItems = [...(formData.lineItems || [])];
    updatedLineItems.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, lineItems: updatedLineItems }));
  };

  const handlePasteArrayItem = (index) => {
    const updatedLineItems = [...(formData.lineItems || [])];
    let obj = { item: updatedLineItems[index], index };
    setPasteData(obj);
  };


  const handleArrayItemChange = (event, index) => {
    const { name, value } = event.target;
        //alert(index)
        setSelectedRowIndex(index);

    setFormData((prevData) => {
      const updatedLineItems = (prevData.lineItems || []).map((item, i) =>
        i === index
          ? {
              ...item,
              [name.includes(`lineItems[${index}]`)
                ? name.replace(`lineItems[${index}].`, "")
                : name]: value,
            }
          : item
      );

      return { ...prevData, lineItems: updatedLineItems };
    });
  };


  const handleArrayItemSelection = (event, index) => {
    const { name, value } = event.target;
    //alert(index)
    setSelectedRowIndex(index);
  };


  const handleAddArrayItem = () => {
    const updatedLineItems = [...(formData.lineItems || [])];
    // If no row is selected, add to the end of the list
    const insertIndex = selectedRowIndex >= 0 ? selectedRowIndex + 1 : updatedLineItems.length;
    updatedLineItems.splice(insertIndex, 0, {});
    setFormData((prevData) => ({ ...prevData, lineItems: updatedLineItems }));
    // Reset selected row index
    setSelectedRowIndex(-1);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!disableForm) {
      const errors = validateFormData(formData);
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        console.log("Form has errors:", errors);
      } else {
        setFormErrors({});
        if (formData) postData(formData);
        console.log("Form submitted successfully:", formData);
      }
    }
  };

  const validateFormData = (data) => {
    const errors = {};

    schema.required.forEach((fieldName) => {
      schema.properties[fieldName].required = true;
    });

    Object.keys(schema.properties).forEach((fieldName) => {
      const fieldSchema = schema.properties[fieldName];
      const fieldValue = data[fieldName];
      const pattern = fieldSchema?.pattern;
      const isValid = pattern && new RegExp(pattern).test(fieldValue);

      if (fieldValue && pattern && !isValid) {
        errors[fieldName] = `${fieldSchema.uiLabel || fieldName} is invalid`;
      }
      if (fieldSchema.required && (!fieldValue || fieldValue.trim() === "")) {
        errors[fieldName] = `${fieldSchema.uiLabel || fieldName} is required`;
      }
    });

    if (schema.properties.lineItems && data.lineItems) {
      let lineItemsErrors = {};
      schema.properties.lineItems.items.required.forEach((fieldName) => {
        schema.properties.lineItems.items.properties[fieldName].required = true;
      });
      Object.keys(schema.properties.lineItems.items.properties).forEach(
        (fieldName) => {
          const fieldLineItemSchema =
            schema.properties.lineItems.items.properties[fieldName];
          const lineItemPattern = fieldLineItemSchema?.pattern;

          data.lineItems.map((fieldLineItemValue, i) => {
            const lineItemIsValid =
              lineItemPattern &&
              new RegExp(lineItemPattern).test(fieldLineItemValue[fieldName]);

            if (fieldLineItemValue[fieldName] && lineItemPattern && !lineItemIsValid) {
              lineItemsErrors[`lineItems[${i}][${fieldName}]`] = `${
                fieldLineItemSchema.uiLabel || fieldName
              } is invalid`;
            }
            if (
              fieldLineItemSchema.required &&
              (!fieldLineItemValue[fieldName] ||
                fieldLineItemValue[fieldName].trim() === "")
            ) {
              lineItemsErrors[`lineItems[${i}][${fieldName}]`] = `${
                fieldLineItemSchema.uiLabel || fieldName
              } is required`;
            }
          });
        }
      );
      let convertedLineIetemErrors = transformObject(lineItemsErrors);
      Object.assign(errors, convertedLineIetemErrors);
    }

    return errors;
  };

  function transformObject(obj) {
    const transformedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const match = key.match(/lineItems\[(\d+)\]\[(\w+)\]/);

        if (match) {
          const index = match[1];
          const field = match[2];

          const newKey = `${field}-${index}`;
          const value = `In lineItems row ${parseInt(index) + 1}, ${
            obj[key]
          }`;
          transformedObj[newKey] = value;
        }
      }
    }

    return transformedObj;
  }

  const renderTableButtons = () => {
    return (
      !disableForm && (
        <button
          type="button"
          title="Add a Row (Row is added below selected row)"
          onClick={handleAddArrayItem}
          style={{ ...styles.addButton }}
        >
          <span className="glyphicon glyphicon-plus"></span>
        </button>
      )
    );
  };

  const renderTableSecondaryButton = (index) => {
    return !disableForm ? (
      <button
        type="button"
        title="Remove Row"
        onClick={() => handleRemoveArrayItem(index)}
        style={{ ...styles.removeButton }}
      >
        <span className="glyphicon glyphicon-minus"></span>
      </button>
    ) : (
      <button
        type="button"
        title="Add row to the right"
        onClick={() => handlePasteArrayItem(index)}
        style={{ ...styles.pasteButton }}
      >
        <span className="glyphicon glyphicon-paste"></span>
      </button>
    );
  };

  return (
    <>
      <IsValidPanel
        token={token}
        disabled={disableForm}
        payload={isValidPayload}
        getTask={getTask}
      />
      <div style={styles.formContainer}>
      <form onSubmit={handleSubmit}>
        {Object.keys(schema.properties).map((fieldName) => {
          const fieldSchema = schema.properties[fieldName];
          const isAlertRed = highlightAlerts && fieldSchema.alert === 'red';
          const labelStyle = {
            ...styles.formLabel,
            color: isAlertRed ? 'red' : 'inherit',
          };

          return (
            <div key={fieldName} style={styles.formGroup}>
              <label htmlFor={fieldName} style={labelStyle}>
                {fieldSchema.uiLabel || fieldName}
                {schema?.required.includes(fieldName) && (
                  <span style={styles.formRequired}>*</span>
                )}
              </label>
              {renderInput(fieldName, fieldSchema)}
              {formErrors[fieldName] && (
                <div style={{ color: "red" }}>{formErrors[fieldName]}</div>
              )}
            </div>
          );
        })}

          {schema.properties.lineItems && (
            <div style={{ ...styles.formGroup, ...styles.lineItemBlock }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                {/* {schema.properties.lineItems.uiLabel || "Line Items"} */}
              </label>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    {Object.keys(
                      schema.properties.lineItems.items.properties
                    ).map((itemFieldName) => (
                      <th key={itemFieldName}>
                        {schema.properties.lineItems.items.properties[
                          itemFieldName
                        ].uiLabel || itemFieldName}
                      </th>
                    ))}
                    <th>{renderTableButtons()}</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.lineItems &&
                    formData.lineItems.map((item, index) => (
                      <tr key={index}>
                        {Object.keys(
                          schema.properties.lineItems.items.properties
                        ).map((itemFieldName) => (
                          <td key={itemFieldName}>
                            <input
                              type="text"
                              name={`lineItems[${index}].${itemFieldName}`}
                              title={item[itemFieldName] || ""}
                              value={item[itemFieldName] || ""}
                              onChange={(event) =>
                                handleArrayItemChange(event, index)
                              }
                              onFocus={(event) =>
                                handleArrayItemSelection(event, index)
                              }
                              onClick={(event) =>
                                showGeos && handleFocus(geos['lineItems'][item['id']], item['desc'])
                              }                              
                              placeholder={
                                schema.properties.lineItems.items.properties[
                                  itemFieldName
                                ].uiLabel || ""
                              }
                              style={{
                                ...styles.input,
                                ...(disableForm && styles.disabledInput),
                              }}
                              readOnly={disableForm}
                            />
                            {formErrors.lineItems &&
                              formErrors.lineItems[index] &&
                              formErrors.lineItems[index][itemFieldName] && (
                                <div style={{ color: "red" }}>
                                  {formErrors.lineItems[index][itemFieldName]}
                                </div>
                              )}
                          </td>
                        ))}
                        <td>{renderTableSecondaryButton(index)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {formErrors.lineItems && (
                <div style={{ color: "red", marginLeft: "15px" }}>
                  {formErrors.lineItems}
                </div>
              )}
            </div>
          )}

          <div style={styles.formGroup}>
            {!disableForm && (
              <button type="submit" style={{ ...styles.submitButton }}>
                Submit
              </button>
            )}
            {disableForm && (
              <div className="use-for-train">
                <label htmlFor="useForTraining">Use For Training</label>
                <input
                  type="checkbox"
                  id="useForTraining"
                  name="useForTraining"
                  onChange={useForTrain}
                />
              </div>
            )}
          </div>

          {Object.keys(formErrors).length > 0 && (
            <div style={{ ...styles.errorAlert }}>
              <strong>Error:</strong> There are validation errors in the form.
              {Object.keys(formErrors).map((keyName, i) => (
                <li key={i}>
                  <span>{formErrors[keyName]}</span>
                </li>
              ))}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default JsonSchemaForm;
