import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { UserAuth } from "../context/AuthContext.js";

const Navbar = ({ getTask, doc }) => {
  const { user, handlerLogout } = UserAuth();
  const [textBoxValue, setTextBoxValue] = useState("");


  return (
    <div className="top-navbar">
      <div className="center-container">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Optional Invoice File Name"
            value={textBoxValue}
            onChange={(e) => setTextBoxValue(e.target.value)}
          />
          <button
            className="btn btn-primary"
            onClick={() => getTask(textBoxValue)}
          >
            Review Invoice
          </button>
        </div>
      </div>
      <div className="sign-out-group gap-2">
        {user?.isAdmin && (
          <Link className="admin-pannel" to={"/dashboard/users"}>
            Admin Pannel
          </Link>
        )}
        <button
          className="btn btn-danger sign-out-button"
          onClick={handlerLogout}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Navbar;
