import { useContext, createContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

import ApiService from "../helpers/Services.js";
import storage from "../helpers/storage.js";
import Config from "../Config.js";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: null,
    isAdmin: false,
  });

  const checkUserAccess = async () => {
    let token = storage.getToken();
    const { email } = jwtDecode(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const roles = await ApiService.post(
        "user-management",
        {
          action: "describeUser",
          payload: {
            vendor: "MT",
            // email: "amitdixit+2@gmail.com", // TODO: remove after testing
            email: email,
          },
        },
        headers
      );

      const hasAdminRole = roles.some(
        (role) => role.rolename === "Admin" && role.status === "Active"
      );

      setUser({
        email,
        isAdmin: hasAdminRole,
      });
    } catch (error) {
      console.error(error);
    }
  };

  console.log({ user });
  const handlerLogout = () => {
    // const cognitoDomain = "your-cognito-domain";
    // const clientId = "your-client-id";
    // const region = "your-region"; // e.g., 'us-east-1'
    // const logoutUri = encodeURIComponent("http://localhost:3000/"); // replace with your redirect URI

    const logoutUrl = Config.LogoutUrl;
    window.location.href = logoutUrl;
    storage.clearToken();
    storage.clearFileName();
  };

  useEffect(() => {
    checkUserAccess();
  }, []);

  return (
    <AuthContext.Provider value={{ handlerLogout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
