const storagePrefix = "doc_analyzer_";

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getFileName: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}filename`));
  },
  setFileName: (filename) => {
    window.localStorage.setItem(
      `${storagePrefix}filename`,
      JSON.stringify(filename)
    );
  },
  clearFileName: () => {
    window.localStorage.removeItem(`${storagePrefix}filename`);
  },
};

export default storage;
