import React, { useEffect } from "react";
import Config from "../Config.js";

const loginUrl = Config.LoginUrl;

const LoginCard = () => {
  // Redirect to the login URL as soon as the component mounts
  useEffect(() => {
    window.location.href = loginUrl;
  }, []);

  // Optionally, you can render a message or a loader while the redirection is in progress
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">Redirecting to Sign in</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          Please wait, you are being redirected to the sign-in page...
        </h6>
        {/* You can also add a loader or spinner here */}
      </div>
    </div>
  );
};

export default LoginCard;
