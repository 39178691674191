import React, { useEffect, useState } from "react";
import ApiService from "../helpers/Services.js";
import swal from "sweetalert";
import { toast } from "react-toastify";


const IsValidPanel = ({
  token = "",
  getTask = () => {
    return;
  },
  payload,
  disabled = false,
}) => {
  const reasons = [
    "1: File cannot be processed",
    "1: File is Password Protected",
    "2: File is not an Invoice",
    "3: File isn't Legible",
    "4: Invoice Data Cannot be Interpreted",
    "5: Amount is not in GBP",
    "5: Multiple Invoices",
    "6: Unsupported File Format",
    "8: Multiple Invoices"
  ];
  const [isNotInvoice, setIsNotInvoice] = useState(false);
  const [invalidDocReason, setInvalidDocReason] = useState("");

  useEffect(() => {
    const { isValidDoc, invalidDocReason } = payload;
    if (isValidDoc == "N") {
      setIsNotInvoice(true);
    }
    if (invalidDocReason) {
      setInvalidDocReason(invalidDocReason);
    }
  }, [payload]);

  const handleCheckboxChange = () => {
    setIsNotInvoice(!isNotInvoice);
  };

  const handleNotInvoiceSubmit = async () => {
    // Logic for handling the submit action when checkbox is checked
    console.log("Document marked as not an invoice");
    // Add your submit logic here
    // setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let apiPayload = {
        id: payload?.docId,
        payload: {},
        isValidDoc: isNotInvoice ? "N" : "Y",
        invalidDocReason,
        useForTraining: payload?.useForTraining ? "Y" : "N",
      };
      const data = await ApiService.post("update-tasks", apiPayload, headers);
          getTask();
    } catch (error) {
      //   setLoading(false);
      // toast.error(error.errorMsg);
      console.error(error);
      swal("Something went wrong!!", error?.errorMsg, "error");
    }
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setInvalidDocReason(newValue);
  };

  return (
    <div className="previous-review-data">
      <p>{payload?.title}</p>
      <div className="is-valid-panel">
        <div className="not-invoice-panel">
          <input
            type="checkbox"
            id={`isValid${disabled}`}
            name={`isValid${disabled}`}
            checked={isNotInvoice}
            onChange={handleCheckboxChange}
            disabled={disabled}
            readOnly={disabled}
          />
          <label htmlFor={`isValid${disabled}`}>
            Document is not an invoice
          </label>
        </div>
        {!disabled && isNotInvoice && (
          <>
            <hr />
            <h4>Select Reason</h4>
            <div className="select-container">
              <select value={invalidDocReason} onChange={handleSelectChange}>
                {reasons.map((reason, index) => {
                  return (
                    <option key={index} value={reason}>
                      {reason}
                    </option>
                  );
                })}
              </select>
              <div className="arrow-down">&#9660;</div>
            </div>
            <button onClick={handleNotInvoiceSubmit}>Submit</button>
          </>
        )}
        {disabled && isNotInvoice && (
          <>
            {" "}
            <hr />
            <h4>Reason</h4>
            <p>{payload?.invalidDocReason}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default IsValidPanel;
