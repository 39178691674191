import React from "react";

function ModalFormSkeleton() {
  return (
    <div className="custom-modal-wrapper">
      <div className="loading-custom custom-title mb-2 "></div>
      <div className="loading-custom custom-modal-skeleton mt-4"></div>
      <div className="loading-custom custom-title mb-2 "></div>
    </div>
  );
}

export default ModalFormSkeleton;
